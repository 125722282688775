<template>
  <div>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Control_Product')"
      @accept="removeProduct"
      :active.sync="wrongProductEnable"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> {{ wrongProductTitle }} {{ $t("Design_Message_5") }} </span>
      </div>
    </vs-prompt>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Control_Product')"
      @accept="deleteAllBigProducts"
      :active.sync="askDeleteAllBigProduct"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span>
          {{ $t("Design_Message_4") }}
        </span>
      </div>
    </vs-prompt>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Confirm_Purchase')"
      @accept="controlOrder"
      :active.sync="confirmPurchaseEnable"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <p>
          {{ $t("Design_Message_7") }} {{ getTotalPrice() }}
          {{ $t("Design_Message_8") }}
        </p>
      </div>
    </vs-prompt>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Design_Message_28')"
      @accept="removeItemFromCart"
      @cancel="removeItemFromCartCancel"
      @close="removeItemFromCartCancel"
      :active.sync="removeConfirm"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span> {{ $t("Design_Message_1") }}</span>
      </div>
    </vs-prompt>
    <vs-popup fullscreen :title="popupTitle" :active.sync="popupActive">
      <div style="position: relative">
        <img
          v-if="popupType == 'V'"
          style="width: 32px; height: 32px"
          class="vipFreeImage"
          src="@/assets/images/pages/vip64.png"
          alt=""
        />
        <img
          v-if="popupType == 'F'"
          style="width: 32px; height: 32px"
          class="vipFreeImage"
          src="@/assets/images/pages/free64.png"
          alt=""
        />
        <img class="popupImage" :src="popupImage" alt />
      </div>
    </vs-popup>
    <!-- IF CART HAVE ITEMS -->
    <div class="vx-row p-2 forNormalScreen">
      <!-- LEFT COL -->
      <div class="vx-col lg:w-3/4 w-full">
        <vs-row class="mb-3 p-1 cartList pt-2 pb-2">
          <vs-col vs-type="flex" vs-align="center" vs-lg="3">
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="4"
              >
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="8"
              >
                <b class="cartTitle">{{ $t("Product_ID") }}</b>
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-col vs-lg="9">
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="2"
              >
                <b class="cartTitle"
                  >{{ $t("Size") }} 16 ({{ getHaveSizeProducts16 }})
                </b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="2"
              >
                <b class="cartTitle"
                  >{{ $t("Size") }} 18 ({{ getHaveSizeProducts18 }})</b
                >
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="2"
              >
                <b class="cartTitle"
                  >{{ $t("Size") }} 20 ({{ getHaveSizeProducts20 }})</b
                >
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="2"
              >
                <b class="cartTitle"
                  >{{ $t("Size") }} 22 ({{ getHaveSizeProducts22 }})</b
                >
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="2"
              >
                <b class="cartTitle">{{ $t("Total") }} {{ $t("Quantity") }}</b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="1"
              >
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-col vs-type="flex" vs-align="center" vs-lg="3"> </vs-col>
          <vs-col vs-lg="9">
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="2"
              >
                <vs-switch
                  :disabled="readAndAccept"
                  v-model="selectedSize"
                  vs-value="16"
                  @input="seperateSizeNew('16')"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="2"
              >
                <vs-switch
                  :disabled="readAndAccept"
                  v-model="selectedSize"
                  vs-value="18"
                  @input="seperateSizeNew('18')"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="2"
              >
                <vs-switch
                  :disabled="readAndAccept"
                  v-model="selectedSize"
                  vs-value="20"
                  @input="seperateSizeNew('20')"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="2"
              >
                <vs-switch
                  :disabled="readAndAccept"
                  v-model="selectedSize"
                  vs-value="22"
                  @input="seperateSizeNew('22')"
                />
              </vs-col>

              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="3"
              >
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>

        <vs-row
          :class="{
            cartList: !product.isBigProduct,
            cartList2: product.isBigProduct,
          }"
          class="mb-3 p-1 pt-2 pb-2"
          vs-align="center"
          vs-type="flex"
          :key="product.id"
          v-for="product in shopItem"
          v-if="product.size != 'General'"
        >
          <vs-col vs-lg="3">
            <vs-row vs-type="flex" vs-align="center">
              <vs-col
                style="position: relative"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="4"
              >
                <img
                  v-if="product.type == 'V'"
                  style="width: 16px; height: 16px"
                  class="vipFreeImage"
                  src="@/assets/images/pages/vip24.png"
                  alt=""
                />
                <img
                  v-if="product.type == 'F'"
                  style="width: 24px; height: 24px"
                  class="vipFreeImage"
                  src="@/assets/images/pages/free24.png"
                  alt=""
                />

                <img
                  class="avatar"
                  @error="imageError"
                  :src="product.thumbnail"
                  alt
                  @click="openPopupProductImage(product)"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="8"
              >
                <b class="cartTitle">{{ product.title }}</b>
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-col vs-type="flex" vs-align="center" vs-lg="9">
            <vs-col
              class="inputBackgroundColor"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
              <vs-input-number
                :disabled="readAndAccept"
                tabindex="1"
                min="0"
                size="medium"
                max="500"
                v-model="product.selectedSize[0]"
                @input="
                  NEWupdateItemQuantity(
                    product.id,
                    0,
                    product.selectedSize[0],
                    product.type,
                    product.selectedSize
                  )
                "
              ></vs-input-number>
            </vs-col>
            <vs-col
              class="inputBackgroundColor"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
              <vs-input-number
                :disabled="readAndAccept"
                tabindex="1"
                min="0"
                size="medium"
                max="500"
                v-model="product.selectedSize[1]"
                @input="
                  NEWupdateItemQuantity(
                    product.id,
                    1,
                    product.selectedSize[1],
                    product.type,
                    product.selectedSize
                  )
                "
              ></vs-input-number>
            </vs-col>
            <vs-col
              class="inputBackgroundColor"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
              <vs-input-number
                :disabled="readAndAccept"
                tabindex="1"
                min="0"
                size="medium"
                max="500"
                v-model="product.selectedSize[2]"
                @input="
                  NEWupdateItemQuantity(
                    product.id,
                    2,
                    product.selectedSize[2],
                    product.type,
                    product.selectedSize
                  )
                "
              ></vs-input-number>
            </vs-col>
            <vs-col
              class="inputBackgroundColor"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
              <vs-input-number
                :disabled="readAndAccept"
                tabindex="1"
                min="0"
                size="medium"
                max="500"
                v-model="product.selectedSize[3]"
                @input="
                  NEWupdateItemQuantity(
                    product.id,
                    3,
                    product.selectedSize[3],
                    product.type,
                    product.selectedSize
                  )
                "
              ></vs-input-number>
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
              <b class="cartTitle">{{ product.quantity }} </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="1"
            >
              <vs-button
                :disabled="readAndAccept"
                radius
                @click="
                  removeItem = product;
                  removeConfirm = true;
                "
                color="#FFFFFF"
              >
                <vs-icon icon="delete" color="dark" size="small"></vs-icon>
              </vs-button>
            </vs-col>
          </vs-col>
        </vs-row>

        <vs-row
          :class="{
            cartList: !product.isBigProduct,
            cartList2: product.isBigProduct,
          }"
          class="mb-3 p-1 pt-2 pb-2"
          vs-align="center"
          vs-type="flex"
          :key="product.id"
          v-for="product in shopItem"
          v-if="product.size == 'General'"
        >
          <vs-col vs-lg="3">
            <vs-row vs-type="flex" vs-align="center">
              <vs-col
                style="position: relative"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="4"
              >
                <img
                  v-if="product.type == 'V'"
                  style="width: 16px; height: 16px"
                  class="vipFreeImage"
                  src="@/assets/images/pages/vip24.png"
                  alt=""
                />
                <img
                  v-if="product.type == 'F'"
                  style="width: 24px; height: 24px"
                  class="vipFreeImage"
                  src="@/assets/images/pages/free24.png"
                  alt=""
                />

                <img
                  @click="openPopupProductImage(product)"
                  class="avatar"
                  @error="imageError"
                  :src="product.thumbnail"
                  alt
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="8"
              >
                <b class="cartTitle">{{ product.title }}</b>
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-col vs-type="flex" vs-align="center" vs-lg="9">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="2"
            >
              <b class="cartTitle">{{ product.quantity }} </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="1"
            >
              <vs-button
                :disabled="readAndAccept"
                radius
                @click="
                  removeItem = product;
                  removeConfirm = true;
                "
                color="#FFFFFF"
              >
                <vs-icon icon="delete" color="dark" size="small"></vs-icon>
              </vs-button>
            </vs-col>
          </vs-col>
        </vs-row>
      </div>

      <!-- RIGHT COL -->
      <div class="vx-col lg:w-1/4 w-full">
        <vx-card class="mb-4">
          <span class="text-black">{{ $t("Select_Machine") }}</span>
          <v-select
            v-if="haveMachine"
            :disabled="readAndAccept || machinesOption.length == 1"
            @input="controlProductAndMachineSizes"
            class="mt-2"
            v-model="selectedMachine"
            :placeholder="$t('Machines')"
            :options="machinesOption"
          >
          </v-select>
          <vs-alert :active="machineErrorEnable" color="danger" class="mt-2">
            {{ machineErrorMessage }}</vs-alert
          >
        </vx-card>
        <vx-card class="mb-4">
          <span class="text-black">{{ $t("Note") }}</span>
          <vs-textarea
            :disabled="readAndAccept"
            rows="3"
            autofocus
            class="m-0 w-full"
            label-placeholder="Note"
            v-model="note"
            @input="saveNote"
          />
        </vx-card>
        <vx-card>
          <p class="font-semibold mb-3">{{ $t("Order Details") }}</p>
          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Product_Unit") }}</span>
            <span>{{ getTotalProductUnit() }}</span>
          </div>
          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Resin") }}</span>
            <span>{{ getTotalResin() }} {{ $t("Gr") }}</span>
          </div>

          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Credit") }}</span>
            <span>{{ getTotalPrice() }}</span>
          </div>
          <vs-alert color="warning" title="Warning" v-if="controlCreditAlert">
            {{ $t("Dental_Message_6") }}
          </vs-alert>
          <vs-divider />
          <div
            style="cursor: pointer"
            @click="showConfirmNotify()"
            class="flex flex-wrap items-center pb-6"
          >
            <vs-checkbox
              color="success"
              v-model="readAndAccept"
              :disabled="
                controlAllSizeSelected() ||
                this.selectedMachine == null ||
                !allSizeAvabile
              "
              >{{ $t("Confirm_Order") }}</vs-checkbox
            >
          </div>

          <vs-button
            :disabled="!readAndAccept || placeOrderDisable"
            @click="confirmPurchaseEnable = true"
            class="w-full"
            >{{ $t("Place_Order") }}</vs-button
          >
        </vx-card>
      </div>
    </div>
    <div class="forSmallScreen">
      <div class="mb-2">
        <vx-card v-if="showMachineArea" class="mb-4">
          <span class="text-black"> {{ $t("Select_Machine") }}</span>
          <v-select
            v-if="haveMachine"
            :disabled="readAndAccept"
            @input="controlProductAndMachineSizes"
            class="mt-2"
            v-model="selectedMachine"
            :placeholder="$t('Machines')"
            :options="machinesOption"
          >
          </v-select>
          <vs-alert :active="machineErrorEnable" color="danger" class="mt-2">
            {{ machineErrorMessage }}</vs-alert
          >
        </vx-card>
        <vx-card class="noteCard mb-2 p-0">
          <span class="text-black">{{ $t("Note") }}</span>
          <vs-textarea
            :disabled="readAndAccept"
            rows="3"
            autofocus
            class="m-0 w-full"
            label-placeholder="Note"
            v-model="note"
            @input="saveNote"
          />
        </vx-card>
        <vx-card>
          <p class="font-semibold mb-3">{{ $t("Order Details") }}</p>
          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Product_Unit") }} </span>
            <span>{{ getTotalProductUnit() }}</span>
          </div>
          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Resin") }} </span>
            <span>{{ getTotalResin() }} {{ $t("Gr") }}</span>
          </div>

          <div class="flex justify-between font-semibold mb-3">
            <span>{{ $t("Total") }} {{ $t("Credit") }} </span>
            <span>${{ getTotalPrice() }}</span>
          </div>
          <vs-alert color="warning" title="Warning" v-if="controlCreditAlert">
            {{ $t("Dental_Message_6") }}
          </vs-alert>
          <vs-divider />
          <div
            @click="showConfirmNotify()"
            class="flex flex-wrap items-center pb-6"
          >
            <vs-checkbox
              color="success"
              v-model="readAndAccept"
              :disabled="
                controlAllSizeSelected() ||
                this.selectedMachine == null ||
                !allSizeAvabile
              "
            >
              {{ $t("Confirm_Order") }}
            </vs-checkbox>
          </div>

          <vs-button
            :disabled="!readAndAccept || placeOrderDisable"
            @click="confirmPurchaseEnable = true"
            class="w-full"
            >{{ $t("Place_Order").toUpperCase() }}</vs-button
          >
        </vx-card>
      </div>

      <!-- Left Col -->
      <div>
        <vs-row
          class="mb-2 pt-2 pb-2 pr-2 cartList"
          vs-align="center"
          vs-type="flex"
        >
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Size") }} 16 : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <vs-switch
                v-model="selectedSize"
                vs-value="16"
                @input="seperateSizeNew('16')"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Size") }} 18 : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <vs-switch
                v-model="selectedSize"
                vs-value="18"
                @input="seperateSizeNew('18')"
              />
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Size") }} 20 : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <vs-switch
                v-model="selectedSize"
                vs-value="20"
                @input="seperateSizeNew('20')"
              />
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Size") }} 22 : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <vs-switch
                v-model="selectedSize"
                vs-value="22"
                @input="seperateSizeNew('22')"
              />
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
            </vs-col>
          </vs-row>
        </vs-row>
        <vs-row
          :class="{
            cartList: !product.isBigProduct,
            cartList2: product.isBigProduct,
          }"
          class="mb-2 pt-2 pb-2 pr-2"
          vs-align="center"
          vs-type="flex"
          :key="product.id"
          v-for="product in shopItem"
          v-if="product.size != 'General'"
        >
          <vs-row>
            <vs-col
              style="position: relative"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <img
                v-if="product.type == 'V'"
                style="width: 16px; height: 16px"
                class="vipFreeImage"
                src="@/assets/images/pages/vip24.png"
                alt=""
              />
              <img
                v-if="product.type == 'F'"
                style="width: 24px; height: 24px"
                class="vipFreeImage"
                src="@/assets/images/pages/free24.png"
                alt=""
              />
              <img
                @click="openPopupProductImage(product)"
                class="avatar"
                @error="imageError"
                :src="product.thumbnail"
                alt
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ product.title }}</b>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Size") }} 16 : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <vs-input-number
                :disabled="readAndAccept"
                class="inputBackgroundColor"
                min="0"
                size="medium"
                max="500"
                v-model="product.selectedSize[0]"
                @input="
                  NEWupdateItemQuantity(
                    product.id,
                    0,
                    product.selectedSize[0],
                    product.type,
                    product.selectedSize
                  )
                "
              ></vs-input-number>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Size") }} 18 : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <vs-input-number
                :disabled="readAndAccept"
                class="inputBackgroundColor"
                min="0"
                size="medium"
                max="500"
                v-model="product.selectedSize[1]"
                @input="
                  NEWupdateItemQuantity(
                    product.id,
                    1,
                    product.selectedSize[1],
                    product.type,
                    product.selectedSize
                  )
                "
              ></vs-input-number>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Size") }} 20 : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <vs-input-number
                :disabled="readAndAccept"
                class="inputBackgroundColor"
                min="0"
                size="medium"
                max="500"
                v-model="product.selectedSize[2]"
                @input="
                  NEWupdateItemQuantity(
                    product.id,
                    2,
                    product.selectedSize[2],
                    product.type,
                    product.selectedSize
                  )
                "
              ></vs-input-number>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Size") }} 22 : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <vs-input-number
                :disabled="readAndAccept"
                class="inputBackgroundColor"
                min="0"
                size="medium"
                max="500"
                v-model="product.selectedSize[3]"
                @input="
                  NEWupdateItemQuantity(
                    product.id,
                    3,
                    product.selectedSize[3],
                    product.type,
                    product.selectedSize
                  )
                "
              ></vs-input-number>
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Total") }} {{ $t("Quantity") }} : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="3"
            >
              <b class="cartTitle">{{ sumProductQuantity(product) }} </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="3"
            >
              <vs-button
                :disabled="readAndAccept"
                radius
                @click="
                  removeItem = product;
                  removeConfirm = true;
                "
                color="#FFFFFF"
              >
                <vs-icon icon="delete" color="dark" size="small"></vs-icon>
              </vs-button>
            </vs-col>
          </vs-row>
        </vs-row>

        <vs-row
          :class="{
            cartList: !product.isBigProduct,
            cartList2: product.isBigProduct,
          }"
          class="mb-2 pt-2 pb-2 pr-2"
          vs-align="center"
          vs-type="flex"
          :key="product.id"
          v-for="product in shopItem"
          v-if="product.size == 'General'"
        >
          <vs-row>
            <vs-col
              style="position: relative"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <img
                v-if="product.type == 'V'"
                style="width: 16px; height: 16px"
                class="vipFreeImage"
                src="@/assets/images/pages/vip24.png"
                alt=""
              />
              <img
                v-if="product.type == 'F'"
                style="width: 24px; height: 24px"
                class="vipFreeImage"
                src="@/assets/images/pages/free24.png"
                alt=""
              />
              <img
                @click="openPopupProductImage(product)"
                class="avatar"
                @error="imageError"
                :src="product.thumbnail"
                alt
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ product.title }}</b>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
            >
              <b class="cartTitle">{{ $t("Total") }} {{ $t("Quantity") }} : </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="3"
            >
              <b class="cartTitle">{{ sumProductQuantity(product) }} </b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="3"
            >
              <vs-button
                :disabled="readAndAccept"
                radius
                @click="
                  removeItem = product;
                  removeConfirm = true;
                "
                color="#FFFFFF"
              >
                <vs-icon icon="delete" color="dark" size="small"></vs-icon>
              </vs-button>
            </vs-col>
          </vs-row>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import vSelect from "vue-select";

import defaultImage from "@/assets/images/pages/no_image.png";

export default {
  data() {
    return {
      haveMachine: false,
      machineErrorMessage: "",
      machineErrorEnable: false,
      //Popup
      popupTitle: "",
      popupActive: false,
      popupImage: null,
      popupType: "S",

      wrongProductEnable: false,
      wrongProductId: null,
      wrongProductTitle: "",
      wrongProduct: null,

      confirmPurchaseEnable: false,

      askDeleteAllBigProduct: false,

      showMachineArea: false,
      selectedMachine: null,
      machinesOption: [],

      allSizeAvabile: false,

      selectedSize: [],
      placeOrderDisable: false,
      activeSize: [0, 0, 0, 0, 0],
      readAndAccept: false,
      note: "",

      removeConfirm: false,
      removeItem: null,
      removeItemSizeIndex: 0,
      totalPrice: 0,
    };
  },

  components: {
    vSelect,
  },

  computed: {
    controlCreditAlert() {
      if (this.getCredit - this.getTotalPrice() > 0) {
        return false;
      } else {
        return true;
      }
    },
    shopItem() {
      return this.$store.getters["designMarket/getShopItems"];
    },
    shopItemVisible() {
      return this.$store.getters["designMarket/getShopItemsVisible"];
    },
    getCredit() {
      return this.$store.state.designMarketCredit;
    },
    getMachineTypes() {
      return this.$store.getters["getMachineTypes"];
    },
    getHaveSizeProducts16() {
      var unit = 0;

      this.shopItem.forEach((element) => {
        if (element.size != "General") {
          unit += element.selectedSize[0];
        }
      });

      return unit;
    },
    getHaveSizeProducts18() {
      var unit = 0;

      this.shopItem.forEach((element) => {
        if (element.size != "General") {
          unit += element.selectedSize[1];
        }
      });

      return unit;
    },
    getHaveSizeProducts20() {
      var unit = 0;

      this.shopItem.forEach((element) => {
        if (element.size != "General") {
          unit += element.selectedSize[2];
        }
      });

      return unit;
    },
    getHaveSizeProducts22() {
      var unit = 0;

      this.shopItem.forEach((element) => {
        if (element.size != "General") {
          unit += element.selectedSize[3];
        }
      });

      return unit;
    },
  },
  watch: {
    getMachineTypes(newValue, oldValue) {
      this.getMachineTypes2();
      this.controlProductAndMachineSizes();
    },
  },
  created() {
    if (this.shopItem.length == 0) {
      this.$vs.notify({
        title: "Warning!!",
        text: "Your order list is empty",
        color: "danger",
      });
      this.$router.push("/").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
    if (localStorage.getItem("note") != null) {
      this.note = localStorage.getItem("note");
    }
    this.checkEnableSizes();
    if (this.getMachineTypes.length != 0) {
      this.getMachineTypes2();
    }
    this.controlProductAndMachineSizes();
  },
  methods: {
    deleteAllBigProducts() {
      var shopItemList = [...this.shopItem];
      for (let index = 0; index < shopItemList.length; index++) {
        const element = shopItemList[index];

        if (element.isBigProduct == true) {
          this.$store.dispatch("designMarket/removeItemInShop", element);
        }
      }
      setTimeout(() => {
        this.checkSize();
      }, 500);
    },

    checkSize() {
      var areThereAnySize = false;

      this.shopItem.forEach((element) => {
        if (element.size != "General") {
          areThereAnySize = true;
        }
      });

      if (!areThereAnySize) {
        this.$router.push("/cart").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },

    openPopupProductImage(item) {
      this.popupType = item.type;
      this.popupTitle = item.title;
      this.popupImage = item.image;
      this.popupActive = true;
    },
    controlProductAndMachineSizes() {
      var selectedMac = null;

      if (!this.haveMachine) {
        this.machineErrorMessage = this.$t("Design_Message_29");
        this.machineErrorEnable = true;
        this.showMachineArea = true;
        return;
      }
      if (this.selectedMachine == null) {
        return;
      }

      if (this.getMachineTypes.length > 0) {
        this.getMachineTypes.forEach((element) => {
          if (
            element.name == this.selectedMachine.label ||
            element.name == this.selectedMachine.name
          ) {
            selectedMac = element;
            this.machineErrorEnable = false;
          }
        });
      } else {
        this.machineErrorMessage = this.$t("Design_Message_29");
        this.machineErrorEnable = true;
        return;
      }

      var controlSize = false;

      this.shopItem.forEach((element) => {
        if (
          element.minGeneralWidth > selectedMac.general_width &&
          element.minGeneralHeight > selectedMac.general_height
        ) {
          element.isBigProduct = true;
          controlSize = true;
        } else {
          element.isBigProduct = false;
        }
      });

      if (controlSize) {
        this.allSizeAvabile = false;
        this.$vs.notify({
          color: "warning",
          title: this.$t("Warning"),
          text: this.$t("Design_Message_10"),
        });
        this.askDeleteAllBigProduct = true;
      } else {
        this.allSizeAvabile = true;
      }
    },
    getMachineTypes2() {
      if (this.getMachineTypes.length > 1) {
        this.haveMachine = true;
        this.showMachineArea = true;
        this.machineErrorEnable = false;
        this.machinesOption = [];
        this.getMachineTypes.forEach((machine, index) => {
          this.machinesOption[index] = {
            id: machine.name,
            label: machine.name,
          };
        });
      } else if (this.getMachineTypes.length == 1) {
        this.machinesOption = [];
        this.machinesOption[0] = {
          id: this.getMachineTypes[0].name,
          label: this.getMachineTypes[0].name,
        };
        this.machineErrorEnable = false;
        this.haveMachine = true;
        this.showMachineArea = false;
        this.selectedMachine = this.machinesOption[0];
      } else {
        this.haveMachine = false;
        this.machineErrorMessage = this.$t("Design_Message_29");
        this.machineErrorEnable = true;
        this.showMachineArea = true;
      }
    },
    controlAllSizeSelected() {
      var shopList = this.shopItem;

      for (let index = 0; index < shopList.length; index++) {
        const element = shopList[index];
        if (element.size != "General") {
          if (
            element.selectedSize[0] +
              element.selectedSize[1] +
              element.selectedSize[2] +
              element.selectedSize[3] +
              element.selectedSize[4] !=
            element.quantity
          ) {
            return true;
          }
        }
      }
      return false;
    },
    showConfirmNotify() {
      if (this.shopItem.length == 0) {
        this.$router.push("/design_market");
      }
      if (!this.haveMachine) {
        this.machineErrorMessage = this.$t("Design_Message_29");
        this.machineErrorEnable = true;
        return;
      }
      if (this.showMachineArea && this.selectedMachine == null) {
        this.machineErrorMessage = this.$t("Design_Message_11");
        this.machineErrorEnable = true;
        return;
      } else if (!this.allSizeAvabile) {
        this.controlProductAndMachineSizes();
      }

      if (this.controlAllSizeSelected() == false) {
        return;
      } else {
        this.$vs.notify({
          color: "warning",
          title: this.$t("Warning"),
          text: this.$t("Design_Message_18"),
        });
      }
    },
    checkEnableSizes() {
      var shopList = this.shopItem;

      for (let index = 0; index < shopList.length; index++) {
        const element = shopList[index];
        if (element.size != "General") {
          if (
            element.selectedSize[0] != 0 &&
            !this.selectedSize.includes("16")
          ) {
            this.selectedSize.push("16");
          }
          if (
            element.selectedSize[1] != 0 &&
            !this.selectedSize.includes("18")
          ) {
            this.selectedSize.push("18");
          }
          if (
            element.selectedSize[2] != 0 &&
            !this.selectedSize.includes("20")
          ) {
            this.selectedSize.push("20");
          }
          if (
            element.selectedSize[3] != 0 &&
            !this.selectedSize.includes("22")
          ) {
            this.selectedSize.push("22");
          }
        }
      }
    },
    seperateSizeNew() {
      if (this.selectedSize.length == 0) {
        this.shopItem.forEach((element) => {
          element.selectedSize = [0, 0, 0, 0, 0];
        });
      }

      this.shopItem.forEach((element) => {
        if (element.size != "General") {
          if (this.selectedSize.length != 0) {
            this.activeSize = [0, 0, 0, 0, 0];
            this.selectedSize.forEach((size) => {
              if (size == "16") {
                this.activeSize[0] = 1;
              } else if (size == "18") {
                this.activeSize[1] = 1;
              } else if (size == "20") {
                this.activeSize[2] = 1;
              } else if (size == "22") {
                this.activeSize[3] = 1;
              }
            });

            var newQuantity = Math.floor(
              element.quantity / this.selectedSize.length
            );

            this.activeSize.forEach((element2, index2) => {
              if (element2 == 1) {
                element.selectedSize[index2] = newQuantity;
              } else {
                element.selectedSize[index2] = 0;
              }
            });

            var remaining = element.quantity % this.selectedSize.length;

            var i;
            for (i = 0; i < remaining; i++) {
              var random = Math.floor(Math.random() * 5);

              while (this.activeSize[random] == 0) {
                random = Math.floor(
                  Math.random() * element.selectedSize.length
                );
              }
              element.selectedSize[random] += 1;
            }
          }
        }
      });

      if (this.selectedSize.length != 0) {
        this.shopItem.forEach((element) => {
          element.selectedSize = [
            element.selectedSize[0],
            element.selectedSize[1],
            element.selectedSize[2],
            element.selectedSize[3],
            element.selectedSize[4],
          ];
        });
      }

      localStorage.removeItem("shop");
      localStorage.setItem("shop", JSON.stringify(this.shopItem));
    },
    imageError(event) {
      event.target.src = defaultImage;
      return true;
    },
    removeItemFromCartCancel() {
      this.removeConfirm = false;
    },
    removeItemFromCart() {
      this.removeConfirm = false;
      setTimeout(() => {
        this.$store.dispatch("designMarket/removeItemInShop", this.removeItem);
        this.showConfirmNotify();
        this.checkSize();
      }, 100);
    },
    saveNote() {
      localStorage.setItem("note", this.note);
    },
    sumProductQuantity(product) {
      var quantity = 0;
      product.selectedSize.forEach((element) => {
        quantity += element;
      });

      if (product.size == "General") {
        return product.quantity;
      }

      return quantity;
    },
    NEWupdateItemQuantity(
      productID,
      sizeIndex,
      quantity,
      type = "S",
      selectedSizes = []
    ) {
      selectedSizes.forEach((element, index) => {
        if (element == "") {
          selectedSizes[index] = 0;
        }
      });

      if (isNaN(quantity) || quantity == "") {
        quantity = 0;
      }
      if (this.selectedSize.length == 0) {
        this.$vs.notify({
          color: "warning",
          title: this.$t("Warning"),
          text: this.$t("Design_Message_19"),
        });

        this.shopItem.forEach((element) => {
          if (element.id == productID) {
            element.selectedSize[sizeIndex] = 0;
          }
        });
        return;
      }

      if (
        parseInt(selectedSizes[0]) +
          parseInt(selectedSizes[1]) +
          parseInt(selectedSizes[2]) +
          parseInt(selectedSizes[3]) >=
        5
      ) {
        this.$store.dispatch("designMarket/NEWupdateItemQuantity", {
          quantity: quantity,
          id: productID,
          sizeIndex: sizeIndex,
        });
        var shopListNew = this.shopItem;

        shopListNew.forEach((element) => {
          if (
            element.selectedSize[0] +
              element.selectedSize[1] +
              element.selectedSize[2] +
              element.selectedSize[3] +
              element.selectedSize[4] ==
              0 &&
            element.size != "General"
          ) {
            this.removeItem = element;
            this.removeConfirm = true;
            this.removeItemSizeIndex = sizeIndex;
          }
        });
      } else {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Design_Message_2"),
          color: "warning",
        });

        if (
          parseInt(selectedSizes[0]) +
            parseInt(selectedSizes[1]) +
            parseInt(selectedSizes[2]) +
            parseInt(selectedSizes[3]) <=
          5
        ) {
          var extraQuantity =
            5 -
            parseInt(selectedSizes[0]) -
            parseInt(selectedSizes[1]) -
            parseInt(selectedSizes[2]) -
            parseInt(selectedSizes[3]);
        }
        this.$store.dispatch("designMarket/NEWupdateItemQuantity", {
          quantity: parseInt(quantity) + extraQuantity,
          id: productID,
          sizeIndex: sizeIndex,
        });
        var shopListNew = this.shopItem;

        shopListNew.forEach((element) => {
          if (
            element.selectedSize[0] +
              element.selectedSize[1] +
              element.selectedSize[2] +
              element.selectedSize[3] +
              element.selectedSize[4] ==
              0 &&
            element.size != "General"
          ) {
            this.removeItem = element;
            this.removeConfirm = true;
            this.removeItemSizeIndex = sizeIndex;
          }
        });
      }
    },
    getTotalProductUnit() {
      var productUnit = 0;
      this.shopItemVisible.forEach((element) => {
        productUnit += parseInt(element.quantity);
      });
      this.shopItem.forEach((element) => {
        productUnit += parseInt(element.quantity);
      });
      return productUnit;
    },
    separateSize() {
      var hasSizeProducts = [];

      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      var shopList = [...this.shopItem];

      var i;

      for (i = 0; i < shopList.length; i++) {
        if (shopList[i].size != "General") {
          hasSizeProducts.push(shopList[i]);
        }
      }

      hasSizeProducts.forEach((element) => {
        var elementSizes = element.size;
        element.selectedSize.forEach((elementSize, index) => {
          if (elementSize != 0) {
            this.$store.commit("designMarket/ADD_ITEM_IN_SHOP_VISIBLE", {
              id: element.id,
              image: element.image,
              price: element.price,
              quantity: elementSize,
              resin_gr: element.resin_gr,
              selectedSize: element.selectedSize,
              size: elementSizes[index],
              thumbnail: element.thumbnail,
              title: element.title,
            });
          }
        });
      });
      this.$vs.loading.close();
      this.sendApiRequest();
    },
    handleSendOrderList(status, data) {
      this.$vs.loading.close();
      this.placeOrderDisable = false;

      if (status == 201) {
        this.$store.dispatch(
          "updateDesignMarketCredit",
          (this.getCredit - this.totalPrice).toFixed(2)
        );
        localStorage.removeItem("note");
        this.$router.push("/design_orders");
        this.$store.dispatch("designMarket/clearItemShop");
        this.$vs.notify({
          color: "success",
          title: this.$t("Successful"),
          text: this.$t("Design_Message_12"),
        });
      } else if (status == 204) {
        localStorage.removeItem("note");
        this.$router.push("/design_orders");
        this.$store.dispatch("designMarket/clearItemShop");
        this.$vs.notify({
          color: "warning",
          title: this.$t("Design_Message_13"),
          text: this.$t("Design_Message_14"),
        });
      } else if (status == 400) {
        this.$store.dispatch("designMarket/clearItemShopInvisible");
        if (data.data.code == "410") {
          this.shopItem.forEach((element) => {
            if (parseInt(element.id) == parseInt(data.data.id)) {
              this.wrongProductEnable = true;
              this.wrongProductTitle = element.title;
              this.wrongProduct = element;
            }
          });
        }
      } else if (status == 403) {
        this.$vs.notify({
          color: "danger",
          title: this.$t("Design_Message_15"),
          text: this.$t("Design_Message_16"),
        });
      } else if (status == 500) {
        this.$vs.notify({
          color: "danger",
          title: this.$t("Warning"),
          text: this.$t("Error_Message_1"),
        });
      }
    },
    removeProduct() {
      this.$store.dispatch("designMarket/removeItemInShop", this.wrongProduct);
    },
    sendApiRequest() {
      var generalSize = [];
      this.shopItem.forEach((element) => {
        if (element.size == "General") {
          generalSize.push(element);
        }
      });

      var unoptimizedList = [];
      unoptimizedList = this.shopItemVisible.concat(generalSize);
      var optimizedList = [];

      unoptimizedList.forEach((element) => {
        optimizedList[optimizedList.length] = {
          id: element.id,
          quantity: element.quantity,
          size: element.size,
        };
      });

      var products;

      if (this.selectedMachine) {
        this.getMachineTypes.forEach((machine) => {
          if (machine.name == this.selectedMachine.label) {
            this.selectedMachine = machine;
          }
        });

        products = {
          products: optimizedList,
          note: this.note,
          plate_width: parseInt(this.selectedMachine.general_width),
          plate_height: parseInt(this.selectedMachine.general_height),
          machine_type: this.selectedMachine.name,
        };
      } else {
        products = {
          products: optimizedList,
          note: this.note,
        };
      }

      this.totalPrice = this.getTotalPrice();
      Api.post(API.BASEURL + API.ORDERS, this.handleSendOrderList, products);
    },
    controlOrder() {
      if (this.shopItem.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: this.$t("Cart_Empty"),
          text: this.$t("Design_Message_17"),
        });

        this.$router.push("/designMarket").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
        return;
      }

      this.controlMachinesRequest();
    },
    controlMachinesRequest() {
      Api.get(API.BASEURL + API.ME, this.handleControlMachinesRequest);
    },
    handleControlMachinesRequest(status, data) {
      if (status >= 200 && status < 300) {
        let index = data.machine_types.findIndex(
          (i) => i.name == this.selectedMachine.label
        );
        if (index != -1) {
          this.sendOrder();
        } else {
          this.selectedMachine = null;
          this.readAndAccept = false;
          this.$store.dispatch("setUserInfo", data);
          this.$vs.notify({
            title: this.$t("Warning"),
            text: this.$t("Design_Message_30"),
            color: "warning",
          });
          this.getMachineTypes2();
        }
      }
    },
    sendOrder() {
      this.placeOrderDisable = true;

      this.separateSize();
    },
    getTotalPrice() {
      var shopItems = this.$store.getters["designMarket/getShopItems"];
      var totalPrice = 0;

      shopItems.forEach((element) => {
        totalPrice += element.price * element.quantity;
      });

      return parseFloat(totalPrice.toFixed(2));
    },
    getTotalResin() {
      var shopItems = this.$store.getters["designMarket/getShopItems"];
      var totalResin = 0;

      shopItems.forEach((element) => {
        totalResin += element.resin_gr * element.quantity;
      });

      return parseFloat(totalResin.toFixed(2));
    },
  },
};
</script>

<style lang="scss">
.v-select .vs__dropdown-toggle .vs__actions .vs__clear {
  display: none;
}
.vipFreeImage {
  position: absolute;
  z-index: 99;
  right: 5%;
  top: 5%;
}
.vs__dropdown-option--highlight {
  background: #c89e82 !important;
  color: #fff;
}
.btn-group button {
  padding: 5px 7px 5px 7px !important;
  border: 1px solid #c89e82;
}
.con-vs-popup.fullscreen .vs-popup {
  width: auto !important;
  height: auto !important;
  .popupImage {
    width: 100%;
    height: auto;
  }
}
.inputBackgroundColor {
  background-color: #fff;
  border-radius: 2rem;
}
@media screen and (min-width: 900px) {
  .forSmallScreen {
    display: none !important;
  }
}
@media screen and (max-width: 900px) {
  .forNormalScreen {
    display: none !important;
  }
}

.cartList {
  width: 100%;
  background: #fff;
  border-radius: 0.5rem;
  display: block;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}

.cartList2 {
  width: 100%;
  background: #ff9f43aa;
  border-radius: 0.5rem;
  display: block;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}

.avatar {
  border-radius: 50%;
  width: 3rem;
  height: auto;
}

.smallScreenFontSize {
  font-size: 11px;
}

.cartTitle {
  font-weight: 900;
  font-size: 1rem;
}
.totalProducts {
  background: rgba(255, 158, 66, 0.65);
}
</style>
